import React from "react"

export default props => {
    return (
        <a href={'#'+props.to} onClick={(e)=>{
            var el = document.getElementById(props.to);
            if (el == null)
                return;

            e.preventDefault();
            el.scrollIntoView({behavior: 'smooth'});
            window.history.pushState(null, null, '#' + props.to);
        }}>{props.children}</a>
    )
}